import { DateRangePicker } from "react-date-range";
import "moment-timezone";
import moment from "moment";
import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Nav, Tab } from "react-bootstrap";
import Table from "../../components/Table";
import CommonSearchBar from "../../layouts/SearchBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { deleteSlots, listingSlots } from "../../redux/slots/actions";
import { listingHotels } from "../../redux/hotels/actions";
import { createOutletUsers, listingOutlets } from "../../redux/outlets/actions";
import { SlotsTableColumns } from "./data";
import { editSelectedGroups } from "../../redux/actions";

import {
  deleteAttendance,
  GenerateAttendance,
  listingAttendance,
  LockAttendance,
  SendAttendance,
  UnLockAttendance,
} from "../../redux/attendance/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import CreateGroup from "../groups/CreateGroup";
import Pagination from "../../components/customPaginationComponent/customPagination";

const AttendanceListingPage = () => {
  useEffect(() => {
    dispatch(listingHotels({ page: 0, limit: 10 }));
  }, []);

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  // const [checeked, setChecked] = useState(true);
  const [responsiveModal, setResponsiveModal] = useState(false);
  const [responsiveModalDate, setResponsiveModalDate] = useState(false);
  const [
    responsiveModalForDeleteAttendance,
    setResponsiveModalForDeleteAttendance,
  ] = useState(query.get("delete") ? true : false);
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: moment(new Date()).subtract(7, "days"),
      key: "selection",
    },
  ]);
  const toggleResponsiveModal = () => setResponsiveModal(!responsiveModal);

  const toggleResponsiveModalDate = () =>
    setResponsiveModalDate(!responsiveModalDate);

  const toggleResponsiveModalForDeleteAttendance = () => {
    setResponsiveModalForDeleteAttendance(!responsiveModalForDeleteAttendance);
    navigate(-1);
  };

  const dispatch = useDispatch();
  const { attendanceList } = useSelector(
    (state: RootState) => state.AttendanceReducer
  );
  const navigate = useNavigate();
  const { name, hotel, outlet, page, limit } = useParams();

  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const { outletsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );
  const {sendAttendanceLoading} = useSelector((state:RootState) => state.AttendanceReducer)

  const deleteParam = query.get("delete");
  const editParam = query.get("edit");
  const addParam = query.get("add");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  useEffect(() => {
    
    if (editParam) {
      dispatch(editSelectedGroups(hotel));
    }
  }, [page, limit]);

  useEffect(() => {
    navigate(
      `/attendance/list/${page}/${limit}/all/all/all?startDate=${moment(
        state[0].startDate
      ).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(
        state[0].endDate
      ).format("YYYY-MM-DDThh:mm:ss")}`
    );
    dispatch(
      listingAttendance({
        page: currentPage,
        limit: 10,
        startDate: moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss"),
        endDate: moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss"),
      })
    );
  }, [state]);

  const searchSlots = (e: any) => {
    if (!e.target.value) {
      navigate(
        `/attendance/list/${page}/${limit}/all/all/all?startDate=${moment(
          state[0].startDate
        ).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(state[0].endDate)
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss")}`
      );
    } else {
      navigate(
        `/attendance/list/${page}/${limit}/${
          e.target.value
        }/all/all?startDate=${moment(state[0].startDate).format(
          "YYYY-MM-DDThh:mm:ss"
        )}&endDate=${moment(state[0].endDate)
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss")}`
      );
    }
    dispatch(
      listingAttendance({
        name: e.target.value,
        limit: limit,
        page: page,
        startDate: state[0].startDate,
        endDate: state[0].endDate,
      })
    );
  };

  const deleteEvent = () => {
    dispatch(deleteAttendance(query.get("delete")));
    query.delete("delete");
    setResponsiveModalForDeleteAttendance(!responsiveModalForDeleteAttendance);
    navigate(-1);
  };
  

  const searchByName = (name: any, value: any) => {
    if (name === "hotels") {
      navigate(
        `/attendance/list/${page}/${limit}/${name}/${
          value ? value : "all"
        }/${outlet}?startDate=${moment(state[0].startDate).format(
          "YYYY-MM-DDThh:mm:ss"
        )}&endDate=${moment(state[0].endDate)
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss")}`
      );
      dispatch(listingHotels({ name: value ? value : "" }));
      // dispatch(listingHotels({name: value}))
    } else {
      console.log("the srachh hote", name, value)
      navigate(
        `/attendance/list/${page}/${limit}/${name}/${hotel}/${
          value ? value : "all"
        }?startDate=${moment(state[0].startDate).format(
          "YYYY-MM-DDThh:mm:ss"
        )}&endDate=${moment(state[0].endDate)
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss")}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          name: value ? value : "",
          hotel: hotel,
        })
      );
    }
  };

  const filterValueChangeByHotelsOrOutlets = (fieldname: any, e: any) => {
    if (fieldname === "hotels") {
      navigate(
        `/attendance/list/${page}/${limit}/${name}/${
          e[0]?.value ? e[0]?.value : "all"
        }/${outlet}?startDate=${moment(state[0].startDate).format(
          "YYYY-MM-DDThh:mm:ss"
        )}&endDate=${moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss")}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel: e[0]?.value  ? e[0]?.value : "",
          page: page,
          limit: limit,
        })
      );
      dispatch(
        listingAttendance({
          hotel: e[0]?.value  ? e[0]?.value : '',
          page: page,
          limit: limit,
          name: name === "all" ? "" : name,
          startDate: state[0].startDate,
          endDate: state[0].endDate,
        })
      );
    } else if (fieldname === "outlets") {
    
      navigate(
        `/attendance/list/${page}/${limit}/${name}/${hotel}/${
          e[0]?.value ? e[0]?.value : "all"
        }?startDate=${moment(state[0].startDate).format(
          "YYYY-MM-DDThh:mm:ss"
        )}&endDate=${moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss")}`
      );
      // alert(e[0]?.value)
      dispatch(
        listingAttendance({
          outlet:
            e[0]?.value 
              ? e[0]?.value
              : "",
          hotel: hotel !== "all" ? hotel : "",
          page: page,
          limit: limit,
          name: name !== "all" ? name : "",
          startDate: state[0].startDate,
          endDate: state[0].endDate,
        })
      );
    }
  };

  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };
  const outletOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.outletName ? list.outletName : " ",
      })
    );
    return hotel;
  };

  const columns = [
    {
      Header: "ID",
      accessor: "_id",
      sort:false,
    },
    {
      Header: "Attendance Name",
      accessor: "attendanceName",
      sort:false,
    },
    {
      Header: "Hotel",
      accessor: "hotel",
      sort: false,
    },
    {
      Header: "Outlet",
      accessor: "outlet",
      sort:false,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: ({ cell }: any) => (
        <>
          <span style={{cursor:"pointer"}} onClick={() => handleEditSlots(cell.row.original)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          &nbsp;
          <span style={{cursor:"pointer"}} onClick={() => handleDeleteSupport(cell.row.values)}>
            <i className="bi bi-trash3"></i>
          </span>
        </>
      ),
    },
    {
      Header: "Send/Download",
      accessor: "send",
      sort: false,
      Cell: ({ cell }: any) => (
        <div style={{display:'flex', justifyContent: "space-evenly"}}>
          {sendAttendanceLoading ? (
            <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
          )
        :
        (
          <>
          <span style={{cursor:"pointer"}} onClick={() => {
          console.log("the id", cell.row.original._id)
          dispatch(SendAttendance(cell.row.original._id))
        }}>
            <i className="bi bi-send"></i>
          </span>
          &nbsp;
          <span  style={{cursor:"pointer"}} onClick={() => dispatch(GenerateAttendance(cell.row.values._id))}>
            <i className="bi bi-download"></i>
          </span>
          </>
        )
        }
        
        </div>
      ),
    },
    {
      Header: "Lock/unLock",
      accessor: "isLocked",
      sort: false,
      // Cell: ({ cell }: any) => (
      //   <>
      //     <span>
      //       <Form>
      //         <Form.Check
      //           onChange={(e: any) => {
      //             console.log("the",e.target.checked)
                  
      //           }}
      //           type="switch"
      //           id="custom-switch"
      //           label=""
      //           checked={cell.row.original.isLocked}
      //         />
      //       </Form>
      //     </span>
      //   </>
      // ),
    },
  ];
  
  const tabContents = () => {
    let i;
    let j;
    const arr = [];
    for (i = 7; i >= 0; i--) {
      arr.push({
        index: i,
        date: moment(new Date()).subtract(i, "days"),
        dateShow: moment(new Date()).subtract(i, "days").format("MMM DD"),
      });
    }
    for (j = 1; j <= 7; j++) {
      arr.push({
        index: j,
        date: moment(new Date()).add(j, "days"),
        dateShow: moment(new Date()).add(j, "days").format("MMM DD"),
      });
    }
    return arr;
  };
  const handleEditSlots = (cell: any) => {
    navigate(`/attendance/list/edit/${cell._id}`);
  };

  const handleDeleteSupport = (cell: any) => {
    navigate(`/attendance/list?delete=${cell._id}`);
    setResponsiveModalForDeleteAttendance(!responsiveModalForDeleteAttendance);
  };

  const lockAttendance = (e: any, details: any) => {
    if (e) {
      dispatch(LockAttendance(details?._id, {
        hotel: hotel !== "all" ? hotel : "",
        outlet: outlet !== "all" ? outlet : "",
        name: name !== "all" ? name : "",
        page: page,
        limit: 10,
        startDate: startDate,
        endDate: endDate,
      }));
   
    } else {
      dispatch(UnLockAttendance(details?._id,{
        hotel: hotel !== "all" ? hotel : "",
        outlet: outlet !== "all" ? outlet : "",
        name: name !== "all" ? name : "",
        page: page,
        limit: 10,
        startDate: startDate,
        endDate: endDate,
      }));
      
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  return (
    <>
      <Modal
        show={responsiveModalForDeleteAttendance}
        onHide={toggleResponsiveModalForDeleteAttendance}
      >
        <Modal.Body className="p-2">
          {deleteParam && (
            <>
              <div>
                <h3>
                  Are you sure you want to delete the selected attendance?
                </h3>
                <div className="text-end">
                  <Button
                    variant="success"
                    type="submit"
                    className="waves-effect waves-light me-1"
                    onClick={() => deleteEvent()}
                  >
                    Save
                  </Button>
                  <Button
                    variant="danger"
                    className="waves-effect waves-light"
                    onClick={toggleResponsiveModalForDeleteAttendance}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={responsiveModalDate} onHide={toggleResponsiveModalDate}>
        <DateRangePicker
          onChange={(item: any) => setState([item.selection])}
          // onChange={item => setState([item.selection])}
          // showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </Modal>

      <h1>Attendance</h1>

      <div>
        <CommonSearchBar
          name={name}
          searchTitle={"Search Attendance..."}
          search={(e: any) => searchSlots(e)}
          searchByName={searchByName}
          filterValueChanged={filterValueChangeByHotelsOrOutlets}
          filters={[
            {
              label: "Hotels",
              name: "hotels",
              placeholder: "Please select a hotel",
              options: hotelOptionsListForSearch(hotelsList),
              isSelect: true,
            },

            {
              label: "Outlets",
              name: "outlets",
              placeholder: "Please select a outlet",
              options: outletOptionsListForSearch(outletsList),
              isSelect: true,
            },
          ]}
        />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          {state && (
            <span
              style={{
                background: "lightgrey",
                width: "20%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "6px",
              }}
              onClick={() => setResponsiveModalDate(true)}
            >
              {moment(state[0].startDate).format("MMM DD")} -{" "}
              {moment(state[0].endDate).format("MMM DD")}
            </span>
          )}
        </div>
        <div>
          <Col xl={12}>
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="Profile">
                  <Nav
                    as="ul"
                    variant="pills"
                    justify
                    className="navtab-bg"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      flexWrap: "nowrap",
                    }}
                  >
                    {(tabContents() || []).map((tab, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link
                            className="cursor-pointer"
                            href="#"
                            style={{
                              cursor: "pointer",
                            }}
                            eventKey={tab.dateShow}
                            // eventKey={(moment(startDate).format("MMM DD") === moment(endDate).format("MMM DD")) ? moment(startDate).format("MMM DD") : ''}
                            onClick={() => {
                              console.log(
                                "diff",
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "YYYY-MM-DD", "days")
                                )
                              );
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) < 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    endDate: moment(tab.date).toDate(),
                                    startDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) > 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    startDate: moment(tab.date).toDate(),
                                    endDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                            }}
                          >
                            {tab.dateShow}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {
              <Table
                columns={columns}
                data={SlotsTableColumns(attendanceList, lockAttendance)}
                isSortable={true}
                pagination={false}
              />
            }
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={attendanceList?.totalCount}
              pageSize={10}
              onPageChange={(page: any) => {
                setCurrentPage(page);
                navigate(
                  `/attendance/list/${page}/${limit}/${name}/${hotel}/${outlet}?startDate=${moment(
                    state[0].startDate
                  ).format("YYYY-MM-DDThh:mm:ss")}&endDate=${moment(
                    state[0].endDate
                  ).format("YYYY-MM-DDThh:mm:ss")}`
                );
                dispatch(
                  listingAttendance({
                    hotel: hotel !== "all" ? hotel : "",
                    outlet: outlet !== "all" ? outlet : "",
                    name: name !== "all" ? name : "",
                    page: page,
                    limit: 10,
                    startDate: startDate,
                    endDate: endDate,
                  })
                );
              }}
            />
            {
              <CreateGroup
                outletsList={outletsList}
                addParam={addParam}
                deleteParam={deleteParam}
                editParam={editParam}
                deletGroup={deleteEvent}
                responsiveModal={responsiveModal}
                toggleResponsiveModal={toggleResponsiveModal}
              />
            }
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AttendanceListingPage;
