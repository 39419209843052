import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ApproveFinance,
  DownloadFinance,
  listingFINANCE,
  listingFINANCELoading,
  listingPendingFINANCE,
  listingPendingFINANCELoading,
  createSubCategory,
  createSubCategoryLoading,
  listingsubcategory,
  SAVEFINANCELoading,
  SAVEFinance,
} from "../../redux/finance/actions";
import { RootState } from "../../redux/store";
import CommonSearchBar from "../../layouts/SearchBar";
import { Button, Card, Nav, Tab } from "react-bootstrap";
import Table from "../../components/Table";
import { listingUsers } from "../../redux/users/actions";
import { PendingFinanceTableColumns, WalletTableColumns } from "./data";
import Pagination from "../../components/customPaginationComponent/customPagination";
import CreateWalletRequestModal from "./CreateSubCategory";
import { listingHotels } from "../../redux/hotels/actions";
import { listingOutlets } from "../../redux/outlets/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import CreateFinance from "./CreateFinance";

const WalletListPage = () => {
  // imports of the page
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, limit, name, tab } = useParams();
  const {
    financelist,
    financelistLoading,
    pendingfinanceList,
    removeFinanceLaoding,
    listSubcategory,
    saveFinanceLoading,
    downloadFinanceLoading
  } = useSelector((state: RootState) => state.FinanceReducer);
  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const { outletsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );
  const { usersList } = useSelector((state: RootState) => state.UsersReducer);
  //   states
  const [currentPage, setCurrentPage] = useState(page);
  const [newWalletRequestModal, setWAlletRequestModal] = useState(false);
  const [financeModal, setFinanceModal] = useState(false);
  const [selectedhotel, setSelectedhotel] = useState<any>("");
  // initial loading of a page
  useEffect(() => {
    dispatch(listingFINANCELoading());
    dispatch(listingFINANCE({ page: page, limit: limit }));
    dispatch(listingPendingFINANCELoading());
    dispatch(listingPendingFINANCE({ page: page, limit: limit }));
    dispatch(listingsubcategory({ page: page, limit: limit }))
  }, [saveFinanceLoading]);

  console.log(
    "the finance list",
    pendingfinanceList,
    financelist,
    financelistLoading,
    listSubcategory,
  );

  // column definition for the table
  const columns: any = [
    
    {
      Header: "Amount",
      accessor: "amount",
      sort: false,
    },
    {
      Header: "Category",
      accessor: "category",
      sort: false,
    },
    {
      Header: "SubCategory",
      accessor: "subCategoryName",
      sort: false,
    },
    {
      Header: "Approve",
      accessor: "status",
      sort: false,
    },
    {
      Header: "Actions",
      accessor: "",
      Cell: ({ cell }: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          <span
            onClick={() => {
              console.log("the id", cell);
              dispatch(DownloadFinance(cell.row.original));
            }}
          >
            {downloadFinanceLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
              </>
            ) : (
              <>
                <i className="bi bi-download"></i>
              </>
            )}
          </span>
        </div>
      ),
    },
  ];
  // confirm wallet
  const confirmWallet = (e: any, details: any) => {
    console.log("the wallet details", e, details);
    dispatch(ApproveFinance({ finance_id: details?._id, isApproved: e }));
    dispatch(listingFINANCE({ page: page, limit: limit }));
    dispatch(listingPendingFINANCE({ page: page, limit: limit }));
  };
  // modal controls
  const openModal = () => setWAlletRequestModal(!newWalletRequestModal);
  const openFinanceModal = () => setFinanceModal(!financeModal);

  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.name ? list.name : " ",
        category:list.category? list.category: " "
      })
    );
    return hotel;
  };

  const addHotelUsersForAddSlots = (e: any) => {
    console.log("the ee", e);
    const payload = {
      name: e.name,
      category: e?.category[0]?.value, //  MONEY-IN, MONEY-OUT
    };
    dispatch(createSubCategoryLoading());
    dispatch(createSubCategory(payload));
    openModal();
    // setWAlletRequestModal(!newWalletRequestModal)
    // dispatch(lisingWalletConfirmation({ name: name === "all" ? "" : name, page:page, limit:limit }));
  };

  const addFinance = (e:any) => {
    console.log("the ee", e);
    let payload ={ 
      "category":e?.subCategory[0]?.category,
      "subCategory": e?.subCategory[0]?.value,
      "transactionDate": e.transactionDate,
      "amount":e.amount,
      "remarks":e.remarks
      }
    dispatch(SAVEFINANCELoading({}))
    dispatch(SAVEFinance(payload))
  }


  const searchByName = (name: any, value: any) => {
    if (name === "hotels") {
      dispatch(listingHotels({ hotelName: value ? value : "" }));
    }
    if (name === "user") {
      dispatch(
        listingUsers({ userStatus: "REGISTERED", name: value ? value : "" })
      );
    }
    if (name === "outlets") {
      selectedhotel &&
        dispatch(
          listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
            name: value ? value : "",
            hotel: selectedhotel,
          })
        );
    }
  };

  return (
    <>
      <h1>Finance List page</h1>
      {/* create manual wallet */}
      <Button
        style={{ float: "right" }}
        variant="success"
        className="waves-effect waves-light"
        onClick={openModal}
      >
        <i className="mdi mdi-plus-circle me-1"></i> Add SubCategory
      </Button>

      {/* modal */}
      <CreateWalletRequestModal
        responsiveModalForAddWalletRequest={newWalletRequestModal}
        toggleResponsiveModalForAddWalletRequest={openModal}
        addHotelUsersForAddSlots={addHotelUsersForAddSlots}
        setResponsiveModalForAddWalletRequest={setWAlletRequestModal}
      />
      <CreateFinance
       responsiveModalForAddWalletRequest={financeModal}
       toggleResponsiveModalForAddWalletRequest={openFinanceModal}
       addFinance={addFinance}
       subCategoryList={hotelOptionsListForSearch(listSubcategory?.data) ? hotelOptionsListForSearch(listSubcategory?.data) : []}
       setResponsiveModalForAddWalletRequest={setWAlletRequestModal}
      />
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={tab === "0" ? "first" : "second"}
        onSelect={(e) => {
          navigate(
            `/finance/${page}/${limit}/${e === "first" ? "0" : "1"}/${name}`
          );
        }}
      >
        <Nav as="ul" variant="tabs" className="nav nav-tabs nav-bordered">
          <Nav.Item as="li">
            <Nav.Link eventKey="first" className="cursor-pointer">
              Approved Finance List
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="second" className="cursor-pointer">
              Pending Finance List
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            {/* table */}
            <Card>
              <Card.Body>
                <Table
                  columns={columns}
                  data={WalletTableColumns(financelist, confirmWallet)}
                  isSortable={true}
                />
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={0}
                  pageSize={10}
                  onPageChange={(page: any) => {
                    setCurrentPage(page);
                    navigate(`/finance/${page}/${limit}/${name}`);
                    // dispatch(listingUsers({userStatus: userStatus,page: page,limit: limit}))
                  }}
                />
              </Card.Body>
            </Card>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <div>
              <Button
                style={{ display: "flex", marginBottom: '10px' }}
                variant="success"
                className="waves-effect waves-light"
                onClick={openFinanceModal}
              >
                <i className="mdi mdi-plus-circle me-1"></i> Add Finance
              </Button>
            </div>
            {/* <div> */}
              <Card>
                <Card.Body>
                  <Table
                    columns={columns}
                    // data={[]}
                    data={PendingFinanceTableColumns(pendingfinanceList, confirmWallet)}
                    isSortable={true}
                  />
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    // totalCount={walletList?.totalCount}
                    pageSize={10}
                    onPageChange={(page: any) => {
                      setCurrentPage(page);
                      navigate(`/wallet/${page}/${limit}/${name}`);
                      // dispatch(listingUsers({userStatus: userStatus,page: page,limit: limit}))
                    }}
                  />
                </Card.Body>
              </Card>
            {/* </div> */}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default WalletListPage;
