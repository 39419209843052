import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import groupSaga from "./groups/saga";
import supportSaga from "./support/saga";
import userSaga from "./users/saga";
import hotelSaga from "./hotels/saga";
import outletSaga from "./outlets/saga";
import outletUsersSaga from "./outletUsers/saga";
import slotsSaga from "./slots/saga";
import attendanceSaga from "./attendance/saga";
import StaffStatusSaga from "./staffStatus/saga";
import StaffAttendanceSaga from "./staffAttendance/saga";
import WalletSaga from "./wallets/saga";
import invoiceSaga from './invoice/saga';
import soaSaga from './soa/saga';
import FinanceSaga from "./finance/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    groupSaga(),
    supportSaga(),
    userSaga(),
    hotelSaga(),
    outletSaga(),
    outletUsersSaga(),
    slotsSaga(),
    attendanceSaga(),
    StaffStatusSaga(),
    StaffAttendanceSaga(),
    WalletSaga(),
    invoiceSaga(),
    soaSaga(),
    FinanceSaga()
  ]);
}
