
// constants
import { UserActionTypes } from "./constants";

const INIT_STATE = {
  // user: api.getLoggedInUser(),
  userloading: false,
  usersList: [],
  page:0,
  limit:5,
  selectedUsers:{},
  colleges: [],
  paymentHistoryLoading: false,
  paymentHistory: [],
  walletHistoryLoading: false,
  walletHistory:[]
};


interface AuthActionType {
  type:
    | UserActionTypes.API_USERS_RESPONSE_ERROR
    | UserActionTypes.API_USERS_RESPONSE_SUCCESS
    | UserActionTypes.LIST_USERS
    | UserActionTypes.CREATE_USERS
    | UserActionTypes.RESET_USERS
    | UserActionTypes.DELET_USERS
    | UserActionTypes.EDIT_USERS_SELECTED
    | UserActionTypes.USERS_PAYMENT_HISTORY
    | UserActionTypes.USERS_PAYMENT_HISTORY_LOADING
    | UserActionTypes.USERS_REDEEM_HISTORY
    | UserActionTypes.USERS_REDEEM_HISTORY_LOADING
    | UserActionTypes.NAVIGATION;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  userloading: boolean,
  usersList: any[],
  page: number,
  limit:number,
  selectedUsers:any,
  colleges:any[],
  paymentHistoryLoading: boolean,
  paymentHistory: any[],
  walletHistoryLoading: boolean,
  walletHistory:any[]
}

const UsersReducer = (state: State = INIT_STATE, action: AuthActionType): any => {
  switch (action.type) {
    case UserActionTypes.API_USERS_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserActionTypes.USERS_PAYMENT_HISTORY_LOADING: {
          return {
            ...state,
            paymentHistoryLoading: true,
          };
        }
        case UserActionTypes.USERS_PAYMENT_HISTORY: {
          return {
            ...state,
            paymentHistoryLoading: false,
            paymentHistory: action.payload.data
          };
        }
        case UserActionTypes.USERS_REDEEM_HISTORY_LOADING: {
          return {
            ...state,
            walletHistoryLoading: true,
          };
        }
        case UserActionTypes.USERS_REDEEM_HISTORY: {
          return {
            ...state,
            walletHistoryLoading: false,
            walletHistory: action.payload.data
          };
        }
        case UserActionTypes.LIST_USERS: {
          return {
            ...state,
            userloading: false,
            usersList: action.payload.data
          };
        }
        case UserActionTypes.LIST_INSTITUTIONS: {
          return {
            ...state,
            colleges: action.payload.data
          };
        }
        case UserActionTypes.EDIT_USERS_SELECTED: {
          return {
            ...state,
            userloading: false,
            selectedUsers:action.payload.data
          };
        }
        case UserActionTypes.EDIT_USERS_STATUS: {
          return {
            ...state,
          };
        }
        case UserActionTypes.DELET_USERS: {
          return {
            ...state,
            userloading: false,
          };
        }
        case UserActionTypes.CREATE_USERS: {
          return {
            ...state,
            loading: false,
            userSignUp: true,
          };
        }
        case UserActionTypes.EDIT_USERS: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
          };
        }
        default:
          return { ...state };
      }

    case UserActionTypes.API_USERS_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserActionTypes.USERS_PAYMENT_HISTORY_LOADING: {
          return {
            ...state,
            paymentHistoryLoading: true,
          };
        }
        case UserActionTypes.USERS_PAYMENT_HISTORY: {
          return {
            ...state,
            paymentHistoryLoading: false,
            paymentHistory: action.payload.error
          };
        }
        case UserActionTypes.USERS_REDEEM_HISTORY_LOADING: {
          return {
            ...state,
            walletHistoryLoading: true,
          };
        }
        case UserActionTypes.USERS_REDEEM_HISTORY: {
          return {
            ...state,
            walletHistoryLoading: false,
            walletHistory: action.payload.error
          };
        }
        case UserActionTypes.LIST_USERS: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case UserActionTypes.LIST_INSTITUTIONS: {
          return {
            ...state,
           collegeError:action.payload.error
          };
        }
        case UserActionTypes.CREATE_USERS: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case UserActionTypes.EDIT_USERS: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        case UserActionTypes.EDIT_USERS_STATUS: {
          return {
            ...state,
            error: action.payload.error,
          };
        }
        default:
          return { ...state };
      }

    // case UserActionTypes.DELET_USERS:
    //   return { ...state, loading: true, userLoggedIn: false };
    // case UserActionTypes.LOGOUT_USER:
    //   return { ...state, loading: true, userLogout: false };
    case UserActionTypes.RESET_USERS:
      return {
        ...state,
        selectedUsers: {}
      };
    default:
      return { ...state };
  }
};

export default UsersReducer;
