import { tab } from "@testing-library/user-event/dist/tab";
import React, { useEffect, useState } from "react";
import { Tab, Nav, Row, Col, Modal, Button, Card } from "react-bootstrap";
import { FormInput } from "../../components";
import CommonSearchBar from "../../layouts/SearchBar";
import FileUpload from "../forms/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import PaidListPage from "./WalletPaidListPage";
import { useDispatch, useSelector } from "react-redux";
import { AcceptPaidList, listPaid, listRedeemList } from "../../redux/wallets/actions";
import moment from "moment";
import { RootState } from "../../redux/store";
import { DateRangePicker } from "react-date-range";
import {
  WalletPadiListTableColumns,
  WalletRedeemListTableColumns,
} from "./data";
import RedeemListPage from "./WalletRedeemListPage";

const WalletPaidandRedeemListPage = () => {
  const { tab, page, limit } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redeemList, paidList } = useSelector(
    (state: RootState) => state.WalletReducer
  );

  // states
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: moment(new Date()).add(7, "days"),
      key: "selection",
    },
  ]);
  const [responsiveModalDate, setResponsiveModalDate] = useState(false);
  const [currentPage, setCurrentPage] = useState(page ? page : 0);
  const [selectedName, setSelectedName] = useState("");

  // date tabs
  const tabContents = () => {
    let i;
    let j;
    const arr = [];
    for (i = 7; i >= 0; i--) {
      arr.push({
        index: i,
        date: moment(new Date()).subtract(i, "days"),
        dateShow: moment(new Date()).subtract(i, "days").format("MMM DD"),
      });
    }
    for (j = 1; j <= 7; j++) {
      arr.push({
        index: j,
        date: moment(new Date()).add(j, "days"),
        dateShow: moment(new Date()).add(j, "days").format("MMM DD"),
      });
    }
    return arr;
  };

  useEffect(() => {
    dispatch(
      listPaid({
        page: page,
        limit: limit,
        startDate: moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss"),
        endDate: moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss"),
      })
    );
    dispatch(listRedeemList({ page: page, limit: limit, name: selectedName }));
  }, []);

  useEffect(() => {
    dispatch(
      listPaid({
        page: currentPage,
        limit: 10,
        startDate: moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss"),
        endDate: moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss"),
      })
    );
  }, [state]);

  useEffect(() => {
    dispatch(listRedeemList({ page: page, limit: limit, name: selectedName }));
  }, [selectedName]);

  const toggleResponsiveModalDate = () =>
    setResponsiveModalDate(!responsiveModalDate);

  const approveRedeem = (e:any, details:any) => {
    console.log("the" , e, details)
    dispatch(AcceptPaidList(details._id,{page:page, limit:limit}))
  }
  return (
    <>
      <Modal show={responsiveModalDate} onHide={toggleResponsiveModalDate}>
        <DateRangePicker
          onChange={(item: any) => {
            setState([item.selection]);
            dispatch(
              listPaid({
                page: currentPage,
                limit: limit,
                startDate: moment(item.selection.startDate).format(
                  "YYYY-MM-DDThh:mm:ss"
                ),
                endDate: moment(item.selection.endDate).format(
                  "YYYY-MM-DDThh:mm:ss"
                ),
              })
            );
          }}
          // onChange={item => setState([item.selection])}
          // showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </Modal>
      <h1>Paid List and Redeem List</h1>

      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={tab === "0" ? "first" : "second"}
        onSelect={(e) =>
          navigate(
            `/wallet-request/${page}/${limit}/${e === "first" ? "0" : "1"}`
          )
        }
      >
        <Nav as="ul" variant="tabs" className="nav nav-tabs nav-bordered">
          <Nav.Item as="li">
            <Nav.Link eventKey="first" className="cursor-pointer">
              Paid List
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="second" className="cursor-pointer">
              Unpaid List
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            >
              {state && (
                <span
                  style={{
                    background: "lightgrey",
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    padding: "6px",
                  }}
                  onClick={() => setResponsiveModalDate(true)}
                >
                  {moment(state[0].startDate).format("MMM DD")} -{" "}
                  {moment(state[0].endDate).format("MMM DD")}
                </span>
              )}
            </div>
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="Profile">
                  <Nav
                    as="ul"
                    variant="pills"
                    justify
                    className="navtab-bg"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      flexWrap: "nowrap",
                    }}
                  >
                    {(tabContents() || []).map((tab, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link
                            className="cursor-pointer"
                            href="#"
                            style={{
                              cursor: "pointer",
                            }}
                            eventKey={tab.dateShow}
                            // eventKey={(moment(startDate).format("MMM DD") === moment(endDate).format("MMM DD")) ? moment(startDate).format("MMM DD") : ''}
                            onClick={() => {
                              console.log(
                                "diff",
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "YYYY-MM-DD", "days")
                                )
                              );
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) < 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    endDate: moment(tab.date).toDate(),
                                    startDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) > 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    startDate: moment(tab.date).toDate(),
                                    endDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                            }}
                          >
                            {tab.dateShow}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Tab.Container>
              </Card.Body>
            </Card>
            <PaidListPage
              paidList={paidList}
              WalletPadiListTableColumns={WalletPadiListTableColumns}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              navigate={navigate}
              limit={limit}
              tab={tab}
              state={state}
              dispatch={dispatch}
              listPaid={listPaid}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <RedeemListPage
              redeemList={redeemList}
              listRedeemList={listRedeemList}
              WalletRedeemListTableColumns={WalletRedeemListTableColumns}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              approveRedeem={approveRedeem}
              navigate={navigate}
              limit={limit}
              tab={tab}
              state={state}
              dispatch={dispatch}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default WalletPaidandRedeemListPage;
