// constants
import { UserActionTypes } from "./constants";

export interface UserActionType {
  type:
    | UserActionTypes.API_USERS_RESPONSE_SUCCESS
    | UserActionTypes.API_USERS_RESPONSE_ERROR
    | UserActionTypes.LIST_USERS
    | UserActionTypes.CREATE_USERS
    | UserActionTypes.EDIT_USERS
    | UserActionTypes.DELET_USERS
    |  UserActionTypes.USERS_PAYMENT_HISTORY
    | UserActionTypes.USERS_PAYMENT_HISTORY_LOADING
    | UserActionTypes.USERS_REDEEM_HISTORY
    | UserActionTypes.USERS_REDEEM_HISTORY_LOADING
    | UserActionTypes.RESET_USERS 
    | UserActionTypes.NAVIGATION
    | UserActionTypes.LIST_INSTITUTIONS
  payload: {} | string;
}

interface UserData {
  type: string;
  group_name: string;
  users: Array<[]>;
  hotel?: string;
  outlet?: string;
}

// common success
export const apiUsersResponseSuccess = (
  actionType: string,
  data: UserData | {}
): UserActionType => ({
  type: UserActionTypes.API_USERS_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const apiUsersResponseError = (
  actionType: string,
  error: string
): UserActionType => ({
  type: UserActionTypes.API_USERS_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const navigateRoute = (
  navigation: any
): any => ({
  type: UserActionTypes.NAVIGATION,
  payload: navigation,
});


export const listingUsers = (
  searchParams: Object
): any => ({
  type: UserActionTypes.LIST_USERS,
  payload: searchParams,
});

export const listingUsersPaymentHistoryLoading = (
  
): any => ({
  type: UserActionTypes.USERS_PAYMENT_HISTORY_LOADING,
});
export const listingUsersPaymentHistory = (
  data: Object
): any => ({
  type: UserActionTypes.USERS_PAYMENT_HISTORY,
  payload: data
});

export const listingUsersRedeemHistoryLoading = (
  
): any => ({
  type: UserActionTypes.USERS_REDEEM_HISTORY_LOADING,
});
export const listingUsersRedeemHistory = (
  data: Object
): any => ({
  type: UserActionTypes.USERS_REDEEM_HISTORY,
  payload: data
});

export const listingCollege = (
  searchParams: Object
): any => ({
  type: UserActionTypes.LIST_INSTITUTIONS,
  payload: searchParams
})

export const editUsers = (
  payload: any,
  id: any
): any => ({
  type:UserActionTypes.EDIT_USERS,
  payload: {payload, id},
})

export const editSelectedUsers = (
  payload: any,
): any => ({
  type:UserActionTypes.EDIT_USERS_SELECTED,
  payload: payload,
})
export const editSelectedUsersStatus = (
  payload: any,
): any => ({
  type:UserActionTypes.EDIT_USERS_STATUS,
  payload: payload,
})
export const deleteUser =(
  id?: string 
): any => ({
  type:UserActionTypes.DELET_USERS,
  id:id
})

export const resetSelectedUsers = (): any => ({
  type: UserActionTypes.RESET_USERS,
  payload: {},
});
