import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import GroupsReducer from "./groups/reducers";
import SupportReducer from "./support/reducers";
import UsersReducer from "./users/reducers";
import HotelsReducer from "./hotels/reducers";
import OutletsReducer from "./outlets/reducers";
import OutletUsersReducer from "./outletUsers/reducers";
import SlotsReducer from './slots/reducers';
import AttendanceReducer from "./attendance/reducers";
import StaffStatusReducer from './staffStatus/reducers';
import StaffAttendanceReducer from "./staffAttendance/reducers";
import WalletReducer from "./wallets/reducers";
import InvoiceReducer from  "./invoice/reducers";
import SOAReducer from "./soa/reducers";
import FinanceReducer from "./finance/reducers";

export default combineReducers({
  Auth,
  Layout,
  GroupsReducer,
  SupportReducer,
  UsersReducer,
  HotelsReducer,
  OutletsReducer,
  OutletUsersReducer,
  SlotsReducer,
  StaffStatusReducer,
  AttendanceReducer,
  StaffAttendanceReducer,
  WalletReducer,
  InvoiceReducer,
  SOAReducer,
  FinanceReducer
});
