import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  deleteUsers as deleteUsersApi,
  listUsers as listUsersApi,
  listusersById as listUsersByIdApi,
  listInstitutions as listCollegeApi,
  editUsersStatus as editUserStatusApi,
  editUsers as editUsersApi,
  listUsersPaymentHistory as listUsersPaymentHistoryApi,
  listUsersRedeemHistory as listUsersRedeemHistoryApi,
  // logout as logoutApi,
  // signup as signupApi,
  // forgotPassword as forgotPasswordApi,
} from "../../helpers/api/users";


// constants
import { UserActionTypes } from "./constants";
// actions
import { apiUsersResponseError, apiUsersResponseSuccess } from "./actions";
import { toast } from "react-toastify";



function* listUsers({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response in  users', searchParams)
    const response = yield call(listUsersApi, searchParams);
 
    yield put(apiUsersResponseSuccess(UserActionTypes.LIST_USERS, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(UserActionTypes.LIST_USERS, err))
  }
}

function* listUsersPaymentHistory({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response in  users', searchParams)
    yield put(apiUsersResponseSuccess(UserActionTypes.USERS_PAYMENT_HISTORY_LOADING,{}))
    const response = yield call(listUsersPaymentHistoryApi, searchParams);
 
    yield put(apiUsersResponseSuccess(UserActionTypes.USERS_PAYMENT_HISTORY, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(UserActionTypes.USERS_PAYMENT_HISTORY, err))
  }
}
function* listUsersRedeemHistory({payload: searchParams,
  type}: any): SagaIterator{
  try{
   
    yield put(apiUsersResponseSuccess(UserActionTypes.USERS_REDEEM_HISTORY_LOADING,{}))
    const response = yield call(listUsersRedeemHistoryApi, searchParams);
    console.log('the response in  users', response)
    yield put(apiUsersResponseSuccess(UserActionTypes.USERS_REDEEM_HISTORY, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(UserActionTypes.USERS_REDEEM_HISTORY, err))
  }
}

function* listColleges({payload: searchParams,
  type
}
: any): SagaIterator{
  try{
const response = yield call(listCollegeApi, searchParams)
console.log('the response in  users', response)
yield put(apiUsersResponseSuccess(UserActionTypes.LIST_INSTITUTIONS, response.data.result))

  }
  catch(err: any){
    yield put(apiUsersResponseError(UserActionTypes.LIST_INSTITUTIONS, err))
  }
}

function* listUsersById({
  payload: details,
  type,
}: any): SagaIterator{
  try{
    const repsonse = yield call(listUsersByIdApi, details);
    console.log('theresp, response', repsonse)
    if(repsonse){
      yield call(listUsersApi, {page:0, limit:5} )
    }
    yield put(apiUsersResponseSuccess(UserActionTypes.EDIT_USERS_SELECTED, repsonse.data.result[0]))
  }
  catch(err:any){
    yield put(apiUsersResponseError(UserActionTypes.EDIT_USERS_SELECTED, err));
 
  }
}

function* editUserStatus({
  payload: details,
  type,
}: any) : SagaIterator{
  try{
    const response = yield call(editUserStatusApi, details)
    if(response) yield call(listUsersByIdApi, details._id)
      toast.success(response.data.info.msg)
    yield put(apiUsersResponseSuccess(UserActionTypes.EDIT_USERS_STATUS, response.data.result[0]))
  
  }
  catch(err:any){
    toast.error(err.response.data.error.msg);
    yield put(apiUsersResponseError(UserActionTypes.EDIT_USERS_STATUS, err));
 
  }
}

function* editUsers({
  payload:data,
  type
}: any) :SagaIterator{
  try{
    const response = yield call(editUsersApi, data.payload)
    
    toast.success(response.data.info.msg)
    yield put(apiUsersResponseSuccess(UserActionTypes.EDIT_USERS, response.data.result[0]))
    // if(response) yield call(listUsersByIdApi, data.id)
  }
  catch(err:any){
    toast.error(err.response.data.error.msg);
    yield put(apiUsersResponseError(UserActionTypes.EDIT_USERS, err));
  }
}
function* deleteUsers({
  id,
  type,
}: any): SagaIterator{
  try{
    console.log("the payel", id)
    const repsonse = yield call(deleteUsersApi, id);
    console.log('theresp, response', repsonse)
    toast.success(repsonse.data.info.msg)
    // if(repsonse) yield call(listUsers,{page:0,limit:5})
    yield put(apiUsersResponseSuccess(UserActionTypes.DELET_USERS,{}))
  }
  catch(err:any){
    toast.error(err.response.data.error.msg);
    yield put(apiUsersResponseError(UserActionTypes.DELET_USERS, err));
 
  }
}
function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    
    yield put(apiUsersResponseSuccess(UserActionTypes.RESET_USERS,{}))
  }
  catch(err:any){
    yield put(apiUsersResponseError(UserActionTypes.RESET_USERS, err));
 
  }
}


export function* watchListingUsers() {
  yield takeEvery(UserActionTypes.LIST_USERS, listUsers);
}
export function* watchListingUsersForPaymentHistory() {
  yield takeEvery(UserActionTypes.USERS_PAYMENT_HISTORY, listUsersPaymentHistory);
}
export function* watchListingUsersForRedeemHistory() {
  yield takeEvery(UserActionTypes.USERS_REDEEM_HISTORY, listUsersRedeemHistory);
}
export function* watchListSupportById(): any {
  yield takeEvery(UserActionTypes.EDIT_USERS_SELECTED, listUsersById);
}
export function* watchDeleteUsers(): any {
  yield takeEvery(UserActionTypes.DELET_USERS, deleteUsers);
}

export function* watchResetSelectedUsers(): any {
  yield takeEvery(UserActionTypes.RESET_USERS, resetValues);
}

export function* navaigate():any{
  // eslint-disable-next-line require-yield
  yield takeEvery(UserActionTypes.NAVIGATION, function*(action: any) {
    console.log('the action', action)
    ;
    action.navigate('/users/list/0/50/all/REGISTERED');
  })
}

export function* watchlistColleges(): any{
  yield takeEvery(UserActionTypes.LIST_INSTITUTIONS,listColleges)
}

export function* watcheditUserStatus(): any{
  yield takeEvery(UserActionTypes.EDIT_USERS_STATUS, editUserStatus)
}

export function* watcheditUsers(): any{
  yield takeEvery(UserActionTypes.EDIT_USERS, editUsers)
}
// export function* watchLogout() {
//   yield takeEvery(UserActionTypes.LOGOUT_USER, logout);
// }

// export function* watchSignup(): any {
//   yield takeEvery(UserActionTypes.SIGNUP_USER, signup);
// }

// export function* watchForgotPassword(): any {
//   yield takeEvery(UserActionTypes.FORGOT_PASSWORD, forgotPassword);
// }

function* userSaga() {
  yield all([
    fork(watchListingUsers),
    fork(watchListSupportById),
    fork(watchDeleteUsers),
    fork(watchResetSelectedUsers),
    fork(navaigate),
    fork(watchlistColleges),
    fork(watcheditUserStatus),
    fork(watcheditUsers),
    fork(watchListingUsersForPaymentHistory),
    fork(watchListingUsersForRedeemHistory)
    // fork(watchLogout),
    // fork(watchSignup),
    // fork(watchForgotPassword),
  ]);
}

export default userSaga;
