import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Users from "../pages/users";
// import Root from './Root';
import UsersEditForm from "../pages/users/userEditForm";
import HotelOutLet from "../pages/hotel-outlet";
import HotelOutLetEditForm from "../pages/hotel-outlet/outletEditForm";
import Slots from "../pages/slots";
import Attendance from "../pages/attendance";
import StaffStatus from  "../pages/staff-status"
import Root from "./Root";
import StaffAttendanceListPage from "../pages/staff-attendance/StaffAttendanceList";
import WalletPages from "../pages/wallet/index";
import WalletPaidandRedeemListPage from "../pages/wallet-requests/WalletPaidList";
import WalletListPages from "../pages/wallet-requests";
import Invoice from "../pages/invoices";
import EditInvoice from "../pages/invoices/editInvoiceDetails";
import InvoiceDetails from "../pages/invoices/invoiceDetails";
import SOA from "../pages/soa";
import FinancePages from "../pages/finance";
import PaymentOrRedeemHistory from "../pages/users/paymentOrRedeemHistory";

// lazy load all the views

// modules
const Groups = React.lazy(() => import("../pages/groups/index"))
const Supports = React.lazy(() => import("../pages/support/index"));
// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
// const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));



// -contacts
const ContactsList = React.lazy(() => import("../pages/apps/Contacts/List/"));


export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// // root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    element: <Root />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/users/list/0/50/all/REGISTERED" />,
      route: PrivateRoute,
    }
  ],
};

// const usersRoutes= {
//   path:"/users",
//   name: "Users",
//   route: PrivateRoute,
//   // roles: ["Admin"],
//   icon: "users",
//   element: <Users />,
//   // header: "Apps",
// }

const usersRoutes = {
  path:"/users",
  name: "Users",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "users",
  children: [
    {
      path: "/users/list/*",
      name: "Users",
      element: <Users />,
      route: PrivateRoute,
    },
    {
      path: "/users/list/edit/:id",
      name: "Users Details",
      element: <UsersEditForm />,
      route: PrivateRoute,
    },
    {
      path: "/users/payment/:type/:id",
      name: "Users Details",
      element: <PaymentOrRedeemHistory />,
      route: PrivateRoute,
    },
  ],
};

const SlotsRoutes = {
  path:"/slots",
  name: "Slots",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "shopping-cart",
  children: [
    {
      path: "/slots/list/*",
      name: "Slots",
      element: <Slots />,
      route: PrivateRoute,
    },
    {
      path: "/slots/edit/:id",
      name: "Slots Details",
      element: <Slots />,
      route: PrivateRoute,
    },
    {
      path: "/slots/add",
      name: "Slot Details",
      element: <Slots />,
      route: PrivateRoute,
    },
    {
      path: "/slots/delete/:id",
      name: "Slot Details",
      element: <Slots />,
      route: PrivateRoute,
    },
  ],
};


const AttendanceRoutes = {
  path:"/attendance",
  name: "Attendance",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "check-square",
  children: [
    {
      path: "/attendance/list/*",
      name: "Attendances",
      element: <Attendance />,
      route: PrivateRoute,
    },
    {
      path: "/attendance/edit/:id",
      name: "Attendances Details",
      element: <Attendance />,
      route: PrivateRoute,
    },
    {
      path: "/attendance/add",
      name: "Attendances Details",
      element: <Attendance />,
      route: PrivateRoute,
    },
    {
      path: "/attendance/delete/:id",
      name: "Attendances Details",
      element: <Attendance />,
      route: PrivateRoute,
    },
  ],
};

const hotelsRoutes = {
  path:"/hotel-outlet",
  name: "Hotels and Outlet",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "home",
  children: [
    {
      path: "/hotel-outlet/list/*",
      name: "Hotels and Outlet",
      element: <HotelOutLet />,
      route: PrivateRoute,
    },
    {
      path: "/hotel-outlet/list/edit/:id",
      name: "Outlet Details",
      element: <HotelOutLetEditForm />,
      route: PrivateRoute,
    },
    {
      path: "/hotel-outlet/list/add",
      name: "Outlet Details",
      element: <HotelOutLetEditForm />,
      route: PrivateRoute,
    },
  ],
};

const GroupsRoutes= {
  path:"/groups/*",
  name: "Groups",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "users",
  element: <Groups />,
}

const ResetPasswordRoutes={
  path:"/reset-password/",
  name: "Reset-Password",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "lock",
  element: <ForgetPassword />,
}

const StaffAttendanceRoutes = {
  path:"/staffAttendance/*",
  name: "Staff Attendance",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "check-square",
  element: <StaffAttendanceListPage />,
}


const StaffStatusRoutes= {
  path:"/staff-status/list/*",
  name: "Staff Status",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "info",
  element: <StaffStatus />,
}
const SupportRoutes= {
  path:"/support/*",
  name: "Support",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "support",
  element: <Supports />,
}

const WalletRoutes= {
  path:"/wallet/*",
  name: "Wallet",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "dollar-sign",
  element: <WalletPages />,
}

const WalletRequestRoutes= {
  path:"/wallet-request/*",
  name: "Wallet Requests",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "dollar-sign",
  element: <WalletListPages />,
}

const FinanceRoutes= {
  path:"/finance/*",
  name: "Finance",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "dollar-sign",
  element: <FinancePages />,
}

const InvoiceRequestRoutes= {
  path:"/invoice",
  name: "Invoices",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "clipboard",
  element: <Invoice />,
  children: [
    {
      path: "/invoice/list/*",
      name: "Invoices",
      element: <Invoice />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/edit/:id",
      name: "Invoices",
      element: <EditInvoice />,
      route: PrivateRoute,
    },
    {
      path: "/invoice/:id",
      name: "Invoices",
      element: <InvoiceDetails />,
      route: PrivateRoute,
    },
  ]
}

const SOARequestRoutes={
  path:"/soa/list/*",
  name: "SOA",
  route: PrivateRoute,
  // roles: ["Admin"],
  icon: "clipboard",
  element: <SOA />,
}




const contactsRoutes = {
  path: "/apps/contacts",
  name: "Contacts",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "book",
  children: [
    {
      path: "/apps/contacts/list",
      name: "Task List",
      element: <ContactsList />,
      route: PrivateRoute,
    }
  ],
};



const appRoutes = [
  contactsRoutes,
];

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    element: <SignInSignUp />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  }
];


// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  usersRoutes,
  GroupsRoutes,
  SupportRoutes,
  hotelsRoutes,
  SlotsRoutes,
  StaffStatusRoutes,
  AttendanceRoutes,
  StaffAttendanceRoutes,
  WalletRoutes,
  WalletRequestRoutes,
  rootRoute,
  ResetPasswordRoutes,
  InvoiceRequestRoutes,
  SOARequestRoutes,
  FinanceRoutes
];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
