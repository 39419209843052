export enum UserActionTypes {
  API_USERS_RESPONSE_SUCCESS = "@@users/API_USERS_RESPONSE_SUCCESS",
  API_USERS_RESPONSE_ERROR = "@@users/API_USERS_RESPONSE_ERROR",

  LIST_USERS = "@@users/LIST_USER",
  CREATE_USERS = "@@users/CREATE_USERS",
  EDIT_USERS = "@@users/EDIT_USERS",
  DELET_USERS = "@@users/DELETE_USERS",
  EDIT_USERS_SELECTED = "@@users/EDIT_USERS_SELECTED",
  LIST_INSTITUTIONS = "@@users/LIST_INSTITUTIONS",
  EDIT_USERS_STATUS = "@@users/EDIT_USERS_STATUS",

  USERS_PAYMENT_HISTORY = "@@users/USERS_PAYMENT_HISTORY",
  USERS_PAYMENT_HISTORY_LOADING = "@@users/USERS_PAYMENT_HISTORY_LOADING",
  USERS_REDEEM_HISTORY  = "@@users/USERS_REDEEM_HISTORY",
  USERS_REDEEM_HISTORY_LOADING = "@@users/USERS_REDEEM_HISTORY_LOADING",


  RESET_USERS = "@@users/RESET_USERS",
  NAVIGATION = "@@users/NAVIGATION"
}
