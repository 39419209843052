import { APICore } from "./apiCore";
import axios from "axios";
import { toast } from "react-toastify";

const api = new APICore();

// account
export function listfinance(payload:any) {
  const baseUrl = "/finance/list";
  // const params =  Object.keys(payload)
  //           .map((key) => key + "=" + payload[key])
  //           .join("&")
    return api.get(`${baseUrl}?page=${payload.page}&limit=${payload.limit}`)
}

export function listPendingfinance(payload:any) {
    const baseUrl = "finance/pending";
    // const params =  Object.keys(payload)
    //           .map((key) => key + "=" + payload[key])
    //           .join("&")
      return api.get(`${baseUrl}?page=${payload.page}&limit=${payload.limit}`)
  }
  

export function downloadfinance(payload: any){
  const baseUrl = "finance/downloadCSV"; 
  axios.get(`${baseUrl}?category=${payload.category}&name=${payload.subCategoryName}`, {
    responseType: 'blob'
 })
 .then((response) => {
    //Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
     const pdfWindow = window.open();
     if(pdfWindow){
        pdfWindow.location.href = fileURL;  
     }
     
     toast.success("Downloaded Successfully");             
  })
  .catch((error) => {
    console.log(error);

    toast.error(error.response.statusText);
  }); 
  // return api.get(`${baseUrl}?category=${payload.category}&name=${payload.subCategoryName}`)
}

export function saveFinance(payload: any){
  const baseUrl = "/finance/add"; 
  return api.create(`${baseUrl}`,payload)
}

export function approveFinance(payload: any){
  console.log("he", payload)
    const baseUrl = "/finance/add"; 
    return api.create(`${baseUrl}?isApproved=${payload.isApproved}&finance_id=${payload.finance_id}`,{})
  }

  export function removeFinanceById(payload: any){
    const baseUrl = "invoice/soa/sendInvoice"; 
    return api.get(`${baseUrl}/${payload}`)
  }

export function listSubcategory(payload: any){
  const baseUrl = "finance/listSubCategory"; 
  return api.get(`${baseUrl}?page=${payload.page}&limit=${payload.limit}`)
}

export function AddSubcategory(payload: any){
    const baseUrl = "finance/addSubCategory"; 
    return api.create(`${baseUrl}`,payload)
  }
  

